// 班车保障
import request from '@/utils/util.js'

/**
 * @description 班车列表
 */
// 列表
export function getShuttleBusesAPI(data) {
	return request.get('/car/guarantee/list', data)
}


// 新增、编辑
export function saveShuttleBusAPI(data) {
	return request.post('/car/guarantee/save', data)
}

// 删除
export function delShuttleBusAPI(data) {
	return request.post('/car/guarantee/delete', data)
}




/**
 * @description 班车类型
 */
// 列表
export function getShuttleBusCategoriesAPI(data) {
	return request.get('/car/guarantee/type_list', data)
}


// 新增、编辑
export function saveShuttleBusCategoryAPI(data) {
	return request.post('/car/guarantee/type_save', data)
}

// 删除
export function delShuttleBusCategoryAPI(data) {
	return request.post('/car/guarantee/type_delete', data)
}