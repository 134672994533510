<template>
  <div>
    <el-form ref="queryForm" :model="queryForm" :inline="true" size="small">
      <el-form-item label="保障人员" prop="realname">
        <el-select v-model="queryForm.personnel" placeholder="请选择" clearable>
          <el-option v-for="item in escorts" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" class="margin-t-10"
      style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="保障时间" prop="time"></el-table-column>
      <el-table-column label="保障类型" prop="type_info.name"></el-table-column>
      <el-table-column label="保障人员" prop="personnel_info.name"></el-table-column>
      <el-table-column label="老干部人数" prop="car_number1"></el-table-column>
      <el-table-column label="阿姨人数" prop="car_number2"></el-table-column>
      <el-table-column label="待开药人数" prop="medicine_number"></el-table-column>
      <el-table-column label="其他人数" prop="car_number3"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="700px">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="保障时间" prop="time">
              <el-date-picker v-model="form.time" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"
                class="el-input_inner--rewrite">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保障类型" prop="type">
              <el-select v-model="form.type" placeholder="请选择" class="el-input_inner--rewrite">
                <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保障人员" prop="personnel">
              <el-select v-model="form.personnel" placeholder="请选择" class="el-input_inner--rewrite">
                <el-option v-for="item in escorts" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="人数" prop="car_number1">
              <el-input-number v-model="form.car_number1" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="阿姨人数" prop="car_number2">
              <el-input-number v-model="form.car_number2" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="待开药人数" prop="medicine_number">
              <el-input-number v-model="form.medicine_number" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他人数" prop="car_number3">
              <el-input-number v-model="form.car_number3" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getShuttleBusesAPI,
    saveShuttleBusAPI,
    delShuttleBusAPI,
    getShuttleBusCategoriesAPI
  } from '@/api/shuttle-bus.js'
  import {
    getEscortsAPI
  } from '@/api/escort.js'

  export default {
    name: 'ShuttleBusList',
    data() {
      return {
        categories: {},
        escorts: [],

        queryForm: {
          page: 1,
          page_size: 10,
        },

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          time: '',
          type: '',
          car_number1: 0,
          car_number2: 0,
          car_number3: 0,
          medicine_number: 0,
          personnel: '',
          remark: '',
        },
        rules: {
          time: [{
            required: true,
            message: '保障时间不能为空',
            trigger: 'change'
          }],
          type: [{
            required: true,
            message: '保障类型不能为空',
            trigger: 'change'
          }],
          personnel: [{
            required: true,
            message: '保障人员不能为空',
            trigger: 'change'
          }]
        },

        tableData: [],
        total: 0,
        tableLoading: false,
        curRowId: '',
      }
    },
    created() {
      this.getShuttleBus()
      getShuttleBusCategoriesAPI().then(res => this.categories = res)
      getEscortsAPI().then(res => this.escorts = res)
    },
    methods: {
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      handleSearch() {
        this.queryForm.page = 1
        this.getShuttleBus()
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
      },
      handleDel(id) {
        delShuttleBusAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getShuttleBus()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveShuttleBusAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getShuttleBus()
          }).finally(() => this.submitting = false)
        })
      },

      getShuttleBus() {
        this.tableLoading = true

        getShuttleBusesAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style>
</style>